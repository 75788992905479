<template>
  <div>
    <v-overlay :value="overlay">
      <v-sheet
        width="350"
        class="px-4 py-2"
        color="deep-purple lighten-5"
        rounded="pill"
      >
        <div class="deep-purple--text font-weight-bold">
          {{$t('modules.customdata.onboarding_screen.create_ai_magic_data.overlay_text')}}
        </div>

        <v-progress-linear
          color="deep-purple accent-4"
          buffer-value="0"
          stream
          height="6"
        ></v-progress-linear>
      </v-sheet>

    </v-overlay>

    <v-alert
      border="left"
      color="indigo"
      dark
    >
      <v-expansion-panels
        flat
        hover
        focusable
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{$t('modules.customdata.onboarding_screen.create_ai_magic_data.header')}}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-switch
                v-if="custom_data_tables.length"
                color="warning"
                v-model="editModel"
                :label="$t('modules.customdata.onboarding_screen.create_ai_magic_data.edit_model_label')"
              ></v-switch>

              <v-select
                v-if="editModel"
                :label="$t('modules.customdata.onboarding_screen.create_ai_magic_data.select_model_label')"
                v-model="modelName"
                item-text="name"
                item-value="name"
                :rules="[rules.required]"
                :items="custom_data_tables"
                dense
                :menu-props="{dark: true}"
                append-icon="mdi-chevron-down"
              />

              <v-textarea
                :hint="$t('modules.customdata.onboarding_screen.create_ai_magic_data.prompt_hint')"
                auto-grow
                v-model="prompt"
                :rules="promptRules"
                counter="1000"
                row-height="20"
                rows="4"
                :label="
              $t(
                'modules.dialog.texts.constants.description'
              )
            "
              ></v-textarea>
              <v-text-field
                label="OpenAI Token"
                class="fieldInput"
                v-model="openai_token"
                :prepend-icon="show_openai_token ? 'mdi-eye' : 'mdi-eye-off'"
                @click:prepend="show_openai_token = !show_openai_token"
                :type="show_openai_token ? 'text' : 'password'"
                autocomplete="null"
                :rules="[rules.required]"
              />

              <v-btn
                light
                block
                :disabled="!valid"
                @click="createModuleAi()"
              >
                {{$t('modules.customdata.onboarding_screen.create_ai_magic_data.btn_submit')}}
              </v-btn>
            </v-form>

          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-alert>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'CreateAiMagicData',

  data() {
    return {
      overlay: false,
      valid: true,
      prompt: null,
      promptRules: [
        (v) => !!v || this.$t("broadcasts.modal.template.rule_required"),
        (v) =>
          v?.length <= 1000 || this.$t("modules.customdata.onboarding_screen.create_ai_magic_data.rule_max_1000"),
      ],
      openai_token: null,
      show_openai_token: false,

      editModel: false,
      modelName: null,
    }
  },

  created() {
    this.setOpenAITokenFromSettings()
  },

  computed: {
    ...mapGetters([
      'rules',
      'fast_line_get_openai_token',
      'custom_data_tables',
    ]),
  },

  methods: {
    ...mapActions([
      'customDataAxiosCreateModuleAi',
      'FastLineAxiosGetOpenAIToken',
    ]),

    createModuleAi() {
      this.overlay = true

      let data = {
        prompt: this.prompt,
        openai_token: this.openai_token,
      }

      if (this.editModel) data['name'] = this.modelName

      this.customDataAxiosCreateModuleAi(data).then((response) => {
        this.prompt = null
        this.openai_token = this.fast_line_get_openai_token || null

        this.editModel = false
        this.modelName = null

        this.$refs.form.reset()
        this.overlay = false
      })
    },

    async setOpenAITokenFromSettings() {
      await this.FastLineAxiosGetOpenAIToken();
      this.openai_token = this.fast_line_get_openai_token
    },

  },
}
</script>

<style scoped lang="scss">

</style>